export default {
  group: [
    {
      name: 'info',
      isArray: false,
      fields: [
        {
          name: 'certificate_series',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'certificate_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'certificate_term_start',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'certificate_term_end',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'address',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'code_main',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'code_rnokpp',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'certificate_validity',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'notes',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'settlement',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'state',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'number_fund',
      isArray: false,
      fields: [
        {
          name: 'number_of_single_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'number_of_double_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'number_of_triple_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'number_of_quadruple_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'number_of_more_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'total_beds_quantity',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'total_capacity',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'conference_halls',
      isArray: true,
      fields: [
        {
          name: 'hall_area',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'hall_capacity',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
  ],
};
