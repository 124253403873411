<template>
  <div class="profile__wrapper">
    <div class="question__wrapper container">
      <div class="block__back" @click="goBack">
        <svg-icon name="arrow-back" />
        <h2 class="title-h4">{{ $t('modals.back') }}</h2>
      </div>
      <div class="profile__title-block">
        <h2 class="title-h2 profile__main-title">
          {{ getCurSTDInfo.title }}
        </h2>
        <star-rating
            :rating="parseInt(getCurSTDInfo.star_category)"
            :read-only="true"
            :star-size="18"
            :fixed-points="1"
            :show-rating="false"
            :padding="8"
            :border-width="2"
            border-color="#6B6B6B"
            active-border-color="#FFB33B"
            active-color="#FFB33B"
            inactive-color="#fff"
        />
      </div>
      <div
          v-for="group in getModel.group"
          :key="group.name"
          class="profile__block"
      >
        <div v-if="!group.isArray">
          <h4 class="title-h4 profile__title">
            {{ $t(`profile.${group.name}`) }}
          </h4>
          <div class="profile__grid">
            <div
                v-for="item in group.fields"
                :key="item.name"
                :class="`${item.column_type}`"
            >
              <text-field
                  v-model="getCurSTDInfo[item.name]"
                  no-underline
                  :title="$t(`profile.${item.name}`)"
                  :is-rate="item.type === 'rate'"
                  :is-icon="item.type === 'text_icon'"
                  :value-mask="item.mask"
                  is-read-only
                  icon-name="actual"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(item, id) in getCurSTDInfo[group.name]" :key="id">
            <h5 class="title-h5 profile__sub-title">
              Конференц зала&nbsp; {{ id + 1 }}
            </h5>
            <div class="profile__grid-subitems">
              <div
                  v-for="item_group in group.fields"
                  :key="item_group.name"
                  :class="`${item_group.column_type}`"
              >
                <text-field
                    v-model="item[item_group.name]"
                    no-underline
                    :title="$t(`profile.${item_group.name}`)"
                    :is-rate="item.type === 'rate'"
                    :is-icon="item.type === 'text_icon'"
                    :value-mask="item.mask"
                    is-disabled
                    icon-name="actual"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contacts__map" @click="openModalOfMap">
        <img src="@/assets/icons/map_blur.png" alt="" />
        <div class="contacts__map-label">
          <div class="contacts__map-icon">
            <svg-icon name="map_google_icon" />
          </div>
          <div class="contacts__map-text">Переглянути розташування готелю</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import profile from '../../../models/landing/hotel';
import { convertDateToCurrentTimeZone } from '@/library/time';
import StarRating from 'vue-star-rating';

export default {
  components: {
    StarRating,
  },
  data() {
    return { componentKey: 0 };
  },

  created() {
    let payload = { id: this.$route.params.id, type: this.$route.meta.type };
    this.$store.dispatch('getSearchResultsDetails', payload);
    this.componentKey += 1;
  },

  computed: {
    ...mapGetters(['getSearchResultDataDetails']),
    getModel() {
      return profile;
    },
    getCurSTDInfo() {
      let obj = {};

      if (this.getSearchResultDataDetails) {
        for (let key in this.getSearchResultDataDetails?.hotel
            ?.certificate_history) {
          if (
              key === 'certificate_term_start' ||
              key === 'certificate_term_end'
          ) {
            obj[key] = convertDateToCurrentTimeZone(
                this.getSearchResultDataDetails.hotel.certificate_history[key]
            ).date;
          } else {
            obj[key] =
                this.getSearchResultDataDetails.hotel.certificate_history[key];
          }
        }
        for (let key in this.getSearchResultDataDetails.hotel) {
          if (key === 'settlement' || key === 'state') {
            let val = !!this.getSearchResultDataDetails.hotel[key]
                ? this.getSearchResultDataDetails.hotel[key].public_name
                : '-';
            obj[key] = val;
          } else {
            let val = !!this.getSearchResultDataDetails.hotel[key]
                ? this.getSearchResultDataDetails.hotel[key]
                : '-';
            obj[key] = val;
          }
        }

        if (!!obj['conference_halls']?.length) {
          obj = Object.fromEntries(
              Object.entries(obj).filter(
                  ([key, value]) => key !== 'conference_halls'
              )
          );
        }
      }
      for (let key in obj) {
        if (typeof obj[key] === 'number') {
          obj[key] = obj[key].toString();
        }
      }
      return obj;
    },
  },

  methods: {
    openModalOfMap() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'map',
        size: 'l',
        props: this.getCurSTDInfo.address.replaceAll(' ', '+'),
      });
    },
    goBack() {
      this.$router.push({ name: 'search-results' });
    },
  },
};
</script>

<style lang="sass" scoped>
.profile__title-block
  display: flex
  gap: 2.4rem
  align-items: center
  margin-bottom: 8rem
  @include xs
    flex-direction: column
    align-items: start
    margin-bottom: 5rem

.profile__sub-title
  margin-bottom: 2rem

.contacts__map img
  width: 100%
.contacts__map
  position: relative
  cursor: pointer
.contacts__map:hover .contacts__map-text
  text-decoration: underline

.contacts__map-label
  width: 100%
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  gap: 16px
  @include s
    gap: 8px
.contacts__map-icon

.contacts__map-text
  text-align: center
  font-size: 18px
  line-height: 22px
  @include m
    font-size: 14px
    line-height: 18px
  @include s
    font-size: 11px
    line-height: 15px
</style>
